import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../ui/Container"
import Portfolio from "../profile/Portfolio"
import { BasicSection } from "../ui/BasicSection"

/**
 * Portfolio / resume page
 */
const PortfolioPage = () => {
  return (
    <Layout>
      <SEO />
      <Container>
        <main>
          <BasicSection>
            <h2>Portfolio</h2>
            <Portfolio />
          </BasicSection>
        </main>
      </Container>
    </Layout>
  )
}

export default PortfolioPage

import React from "react"
import styled from "@emotion/styled"
import { Card } from "./Cards"

export const PortfolioCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 32px;
  align-items: flex-start;
  margin-bottom: 32px;

  @media (min-width: 596px) {
    & > [data-colspan="2"] {
      grid-column-end: span 2;
    }
  }
`

/**
 * Portfolio card
 */
export const PortfolioCard = props => {
  const { title, children, ...other } = props

  return (
    <Card {...other}>
      <h3>{title}</h3>
      {children}
    </Card>
  )
}

import React from "react"
import { PortfolioCardContainer, PortfolioCard } from "../ui/PortfolioCard"
import { Chips, Chip } from "../ui/Chips"
import { Button } from "../ui/Button"

const LibraryItem = (props) => {
  const { to, title, children } = props

  return (
    <li>
      <a href={to}>{title}</a>: {children}
    </li>
  )
}

/**
 * Portfolio section
 */
const Portfolio = (props) => {
  const { variant = "page" } = props
  const isSummary = variant === "summary"

  return (
    <PortfolioCardContainer>
      <PortfolioCard data-colspan={2} title="Redwerks Galaxy">
        <p>
          Using my experience from previous projects I started building a
          foundation for quickly building out new production quality products
          for clients. Using using software like Tailwind UI, Identity Provider
          based user management, Stripe Checkout, and others which are flexible
          but easy to setup that when combined with sample code from my
          prototype sites can speed up the initial work and allow us to quickly
          start on the application logic.
        </p>
        <p>
          Some of this technology has already been used to create a new version
          of Redwerks' website.
        </p>

        <Chips>
          <Chip>Tailwind</Chip>
          <Chip>Remix.run</Chip>
          <Chip>Ory</Chip>
          <Chip>Stripe</Chip>
        </Chips>
        <Button newtab href="https://redwerks.org/">
          redwerks.org
        </Button>
      </PortfolioCard>

      <PortfolioCard data-colspan={2} title="Redwerks Open Source Libraries">
        <p>
          At Redwerks I sometimes build libraries we release as open-source and
          use in client projects.
        </p>

        <ul>
          <LibraryItem
            to="https://github.com/redwerks/material-vega"
            title="Material Vega"
          >
            A series of Material Design visualization themes for Vega charts
          </LibraryItem>
          <LibraryItem
            to="https://github.com/redwerks/eslint-config"
            title="eslint-config"
          >
            A balanced set of eslint configs we use in our projects
          </LibraryItem>
          <LibraryItem
            to="https://github.com/redwerks/landing-typography-theme"
            title="@redwerks/landing-typography-theme"
          >
            A Typography.js theme for our landing page framework
          </LibraryItem>
          <LibraryItem
            to="https://github.com/redwerks/gatsby-terraform-s3"
            title="gatsby-terraform-s3"
          >
            Terraform module for settting up AWS resources to deploy a Gatsby
            site to with gatsby-plugin-s3
          </LibraryItem>
        </ul>
      </PortfolioCard>

      <PortfolioCard title="Broker&nbsp;Scout → Property&nbsp;Fox">
        <p>
          After the acquisition of Broker Scout, I helped Property Fox with
          incrementally building the new Property Fox platform.
        </p>

        <Chips>
          <Chip>Tailwind</Chip>
          <Chip>Next.js</Chip>
          <Chip>Ory</Chip>
          <Chip>Stripe</Chip>
          <Chip>Python</Chip>
          <Chip>Go</Chip>
        </Chips>
        <Button newtab href="https://propertyfox.ai/">
          propertyfox.ai
        </Button>
      </PortfolioCard>

      {!isSummary && (
        <PortfolioCard title="Transactive / Embetter">
          <p>
            I built a new portal UI for Transactive which went into production.
            And then repurposed that portal to work with the APIs they were
            developing as part of their rebranding to Embetter.
          </p>

          <Chips>
            <Chip>React</Chip>
            <Chip>MUI</Chip>
            <Chip>Vite</Chip>
            <Chip>OpenAPI</Chip>
            <Chip>Go</Chip>
            <Chip>Azure</Chip>
            <Chip>Kubernetes</Chip>
            <Chip>Keycloak</Chip>
          </Chips>
        </PortfolioCard>
      )}

      {!isSummary && (
        <PortfolioCard title="Univerus">
          <p>
            <small>More info in the future.</small>
          </p>

          <Chips>
            <Chip>React</Chip>
            <Chip>MUI</Chip>
            <Chip>MSAL</Chip>
            {/* ??? */}
          </Chips>
        </PortfolioCard>
      )}

      {/* Univerus CPS? */}

      <PortfolioCard title="Game Face">
        <p>
          I rebuilt the entire frontend of the Game Face platform and created a
          new messaging system for coaches and athletes.
        </p>

        <Chips>
          <Chip>React</Chip>
          <Chip>MUI</Chip>
          <Chip>Realtime Chat</Chip>
          <Chip>Video Transcoding</Chip>
          <Chip>NestJS</Chip>
          <Chip>TypeORM</Chip>
          <Chip>GraphQL</Chip>
          <Chip>TUC</Chip>
        </Chips>

        <Button newtab href="https://getgameface.com/">
          getgameface.com
        </Button>
      </PortfolioCard>

      {!isSummary && (
        <PortfolioCard title="Agreement Express Mobile App">
          <p>
            Agreement Express contracted me to build a mobile application in
            React Native to go along with the new "mv2" frontend I partially
            built for them.
          </p>

          <Chips>
            <Chip>React Native</Chip>
          </Chips>
        </PortfolioCard>
      )}

      {!isSummary && (
        <PortfolioCard title="Agreement Express Template Builder">
          <p>
            I built the core of a "Template Builder" system. It allowed users to
            author complex web forms with a series of form widgets and column
            layouts. These forms were used to create signable documents used in
            the rest of Agreement Express' platform.
          </p>

          <Chips>
            <Chip>React</Chip>
          </Chips>
        </PortfolioCard>
      )}

      <PortfolioCard
        data-colspan={2}
        title="MediaWiki ShortURL Configuration Tool"
      >
        <p>
          As an internal project when I was still working on MediaWiki projects
          I built a Short URL configuration tool for the MediaWiki community.
          The tool scans the server information from a live MediaWiki
          installation and provides the server and MediaWiki configuration
          necessary to setup Short URLs on the MediaWiki installation.
        </p>

        <p>
          It was originally written in Ruby. But recently I rewrote it as a
          React app and open sourced it.
        </p>

        <Chips>
          <Chip>React</Chip>
          <Chip>MediaWiki</Chip>
        </Chips>
        <Button newtab href="https://shorturls.redwerks.org/">
          shorturls.redwerks.org
        </Button>
      </PortfolioCard>

      <PortfolioCard title="Redwerks's “Kommonwealth” (2008 - 2011)">
        <p>
          As one of my first professional projects I built a web design tool to
          allow web designers to design complex websites and then export them as
          standalone websites or themes for WordPress, Drupal, and MediaWiki.
        </p>
        <Button newtab href="https://youtu.be/s5sEJda9iQw">
          View old promo on YouTube
        </Button>
      </PortfolioCard>

      <PortfolioCard title="MediaWiki">
        <p>
          Prior to starting work professionally I made open-source contributions
          to the MediaWiki software and extensions of it. My Subversion
          contributions can be hard to find but some of my latter contributions
          can be found in{" "}
          <a
            href="https://gerrit.wikimedia.org/r/q/owner:d%2540danf.ca"
            target="_blank"
            rel="noopener"
          >
            Gerrit
          </a>
          .
        </p>
        <p>
          This experience helped greatly in shaping my understanding of security
          and performance on the web.
        </p>
        <Button
          newtab
          href="https://en.wikipedia.org/wiki/Special:Version/Credits#:~:text=Daniel%20Friesen"
        >
          MediaWiki credits
        </Button>
      </PortfolioCard>
    </PortfolioCardContainer>
  )
}

export default Portfolio
